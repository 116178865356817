export const permitsHeaders = [
	{
		id: "numeroPermiso",
		header: "PERMISO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "cuenta",
		header: "CUENTA",
		styles: "",
		currency: false,
		sum: false,
		count: true,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "usuario",
		header: "USUARIO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "nombreProductor",
		header: "PRODUCTOR",
		styles: "longCell",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "seccion",
		header: "SECCION",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "tipoLocalidad",
		header: "EJ/COL",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "localidad",
		header: "LOCALIDAD",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "lote",
		header: "LOTE",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "modulo",
		header: "MODULO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "nombreCultivo",
		header: "CULTIVO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "subciclo",
		header: "SUBCICLO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "supAutorizada",
		header: "HA",
		styles: "text-center",
		currency: false,
		sum: true,
		count: false,
		float: 4,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "cuotaCultivo",
		header: "CUOTA",
		styles: "text-center",
		currency: true,
		sum: true,
		count: false,
		float: 2,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "costoSanidades",
		header: "APOYO SANIDADES",
		styles: "text-center",
		currency: true,
		sum: true,
		count: false,
		float: 2,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "fechaEmicion",
		header: "FECHA",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},

	{
		id: "cultivoAnterior",
		header: "CUL. ANT.",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "estadoPermiso",
		header: "ESTADO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "sistema",
		header: "SISTEMA",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
];

export const laboresHeaders = [
	{
		id: "folio",
		header: "FOLIO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: false,
	},
	{
		id: "cuenta",
		header: "CUENTA",
		styles: "",
		currency: false,
		sum: false,
		count: true,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "nombre",
		header: "USUARIO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "tipoLocalidad",
		header: "EJ/COL",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "claveLocalidad",
		header: "CLAVE",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "ubicacion",
		header: "LOCALIDAD",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "lote",
		header: "LOTE",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "modulo",
		header: "MODULO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "cultivo",
		header: "CULTIVO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "superficie",
		header: "HA",
		styles: "text-center",
		currency: false,
		sum: true,
		count: false,
		float: 4,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "superficieMapeada",
		header: "HA-Map",
		styles: "text-center",
		currency: false,
		sum: true,
		count: false,
		float: 4,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "tecnico",
		header: "TECNICO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "desfoliado",
		header: "DESFOLIADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "cosechado",
		header: "COSECHADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "desvarado",
		header: "DESVARADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "disqueado",
		header: "DISQUEADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "desarraigado",
		header: "DESARRAIGADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "barbechado",
		header: "BARBECHADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "pagado",
		header: "PAGADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "laboresPendientes",
		header: "BLOQUEADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
];

export const producersHeaders = [
	{
		id: "curp",
		header: "CURP",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "apPaterno",
		header: "PATERNO",
		styles: "",
		currency: false,
		sum: false,
		count: true,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "apMaterno",
		header: "MATERNO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "nombre",
		header: "NOMBRE",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "rfc",
		header: "RFC",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "genero",
		header: "GENERO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "direccion",
		header: "DIRECCION",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "municipio",
		header: "MUNICIPIO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "estado",
		header: "ESTADO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "cp",
		header: "CP",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "telefono",
		header: "TELEFONO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	// {
	// 	id: "fechaRegistro",
	// 	header: "REGISTRO",
	// 	styles: "",
	//  currency:false,
	// 	sum: false,
	// 	count: false,
	// 	float: false,
	// 	display: false,
	// 	selectable: true,
	//  search:true
	// }
];

export const usersHeaders = [
	{
		id: "id",
		header: "CUENTA",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: false,
	},
	{
		id: "cuenta",
		header: "CTA",
		styles: "",
		currency: false,
		sum: false,
		count: true,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "subcta",
		header: "SUBCTA",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "apPaterno",
		header: "PATERNO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "apMaterno",
		header: "MATERNO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "nombre",
		header: "NOMBRE",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "tipoLocalidad",
		header: "EJ/COL",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "nombreLocalidad",
		header: "LOCALIDAD",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "ejido",
		header: "CLAVE",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "predio",
		header: "PREDIO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "reacomodo",
		header: "REACOMODO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "seccion",
		header: "SECCION",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "modulo",
		header: "MODULO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "municipio",
		header: "MUNICIPIO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "estado",
		header: "ESTADO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "supRiego",
		header: "SUPRIEGO",
		styles: "text-center",
		currency: false,
		sum: true,
		count: false,
		float: 4,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "supFisica",
		header: "SUPFISICA",
		styles: "text-center",
		currency: false,
		sum: true,
		count: false,
		float: 4,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "sistemaRiego",
		header: "SISTEMA",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "cp",
		header: "CP",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "equipo",
		header: "EQUIPO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "grupo",
		header: "GRUPO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "lt",
		header: "LT",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "pControl",
		header: "PCONTROL",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "ra",
		header: "RA",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "referencia",
		header: "REF",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "slt",
		header: "SLT",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "sra",
		header: "SRA",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "ssra",
		header: "SSRA",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "tenencia",
		header: "TENENCIA",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
];

export const transferEntrantesHeaders = [
	{
		id: "folio",
		header: "FOLIO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "estadoTransferencia",
		header: "ESTADO",
		styles: "",
		currency: false,
		sum: false,
		count: true,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "superficieTransferida",
		header: "HA",
		styles: "text-center",
		currency: false,
		sum: true,
		count: false,
		float: true,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "moduloDestino",
		header: "MODULO-DESTINO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "clavelocalidadDestino",
		header: "CLAVE-DESTINO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "tipolocalidadDestino",
		header: "EJ/COL-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "localidadDestino",
		header: "LOCALIDAD-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "loteDestino",
		header: "LOTE-DESTINO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "apPaternoSolicitante",
		header: "PATERNO-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "apMaternoSolicitante",
		header: "MATERNO-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "nombreSolicitante",
		header: "NOMBRE-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "claveCultivo",
		header: "CLAVE-CULTIVO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "nombreCultivo",
		header: "CULTIVO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "id",
		header: "CUENTA-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "apPaterno",
		header: "PATERNO-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "apMaterno",
		header: "MATERNO-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: 4,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "nombre",
		header: "NOMBRE-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: 4,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "modulo",
		header: "MODULO-ORIGEN",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "predio",
		header: "LOTE-ORIGEN",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "ejido",
		header: "CLAVE-ORIGEN",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "tipoLocalidad",
		header: "EJ/COL-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "nombreLocalidad",
		header: "LOCALIDAD-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
];

export const transferSalientesHeaders = [
	{
		id: "folio",
		header: "FOLIO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "estadoTransferencia",
		header: "ESTADO",
		styles: "",
		currency: false,
		sum: false,
		count: true,
		float: false,
		display: true,
		selectable: false,
		search: true,
	},
	{
		id: "superficieTransferida",
		header: "HA",
		styles: "text-center",
		currency: false,
		sum: true,
		count: false,
		float: true,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "id",
		header: "CUENTA-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "apPaterno",
		header: "PATERNO-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "apMaterno",
		header: "MATERNO-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: 4,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "nombre",
		header: "NOMBRE-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: 4,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "modulo",
		header: "MODULO-ORIGEN",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "predio",
		header: "LOTE-ORIGEN",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "ejido",
		header: "CLAVE-ORIGEN",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "tipoLocalidad",
		header: "EJ/COL-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "nombreLocalidad",
		header: "LOCALIDAD-ORIGEN",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},

	{
		id: "claveCultivo",
		header: "CLAVE-CULTIVO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "nombreCultivo",
		header: "CULTIVO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "moduloDestino",
		header: "MODULO-DESTINO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "clavelocalidadDestino",
		header: "CLAVE-DESTINO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "tipolocalidadDestino",
		header: "EJ/COL-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
	{
		id: "localidadDestino",
		header: "LOCALIDAD-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: true,
	},
	{
		id: "loteDestino",
		header: "LOTE-DESTINO",
		styles: "text-center",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: true,
		selectable: true,
		search: false,
	},
	{
		id: "apPaternoSolicitante",
		header: "PATERNO-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "apMaternoSolicitante",
		header: "MATERNO-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: true,
	},
	{
		id: "nombreSolicitante",
		header: "NOMBRE-DESTINO",
		styles: "",
		currency: false,
		sum: false,
		count: false,
		float: false,
		display: false,
		selectable: true,
		search: false,
	},
];
