import { types } from "../types/types";

const initialState = {
	enEspera: false,
	enableSaveButton: true,
	enablePrintButton: false,
	openUsuariosModal: false,
	openProductoresModal: false,
	isOpenNuevoProductorModal: false,
	openCultivosModal: false,
	openCultivoAnteriorModal: false,
	isOpenPrintPermisoModal: false,
	usuarios: [],
	cultivos: [],
	cultivosAnteriores: [
		{ clave: 0, id: "zaNmqPpV7fVmcSDZ4bu7", nombre: "SIN CULTIVO", superficie: 0 },
	],
	productores: [],
	idUsuarioSelected: null,
	cuenta: null,
	usuario: null,
	supDerecho: null,
	lote: null,
	localidad: null,
	municipio: null,
	estado: null,
	modulo: null,
	seccion: null,
	canal: null,
	toma: null,
	sistema: null,
	idProductorSelected: null,
	nombreProductor: null,
	rfcProductor: null,
	productorIncumplido: null,
	idCultivoSelected: null,
	nombreCultivo: null,
	claveCultivo: null,
	subciclo: null,
	cuotaCultivo: null,
	supPrevia: 0,
	idCultivoAnteriorSelected: null,
	cultivoAnterior: "",
	claveCultivoAnterior: null,
	tipo: "normal",
	ciclo: null,
	numeroPermiso: null,
	fechaEmicion: null,
	fechaLimite: null,
	vigencia: null,
	estadoPermiso: null,
	variedad: "",
	supAutorizada: 0,
	fuenteCredito: "",
	latitud: "",
	longitud: "",
	observaciones: "",
	permisosComplemento: [],
};

export const altaPermisosReducer = (state = initialState, action) => {
	switch (action.type) {
		//Print Permiso **************************************

		case types.altaPermisoOpenPrintPermisoModal:
			return {
				...state,
				openPrintPermisoModal: true,
			};

		case types.altaPermisoClosePrintPermisoModal:
			return {
				...state,
				openPrintPermisoModal: false,
			};

		//Cultivos **************************************
		case types.altaPermisoOpenCultivosModal:
			return {
				...state,
				openCultivosModal: true,
			};

		case types.altaPermisoCloseCultivosModal:
			return {
				...state,
				openCultivosModal: false,
			};

		case types.altaPermisoOpenCultivoAnteriorModal:
			return {
				...state,
				openCultivoAnteriorModal: true,
			};

		case types.altaPermisoCloseCultivoAnteriorModal:
			return {
				...state,
				openCultivoAnteriorModal: false,
			};

		case types.loadCultivos:
			return {
				...state,
				cultivos: action.payload,
			};

		case types.addNuevoCultivo:
			return {
				...state,
				cultivos: [...state.cultivos, { ...action.payload }],
				idCultivoSelected: action.payload.id,
			};

		case types.setCultivosAnteriores:
			return {
				...state,
				cultivosAnteriores: action.payload,
			};

		case types.clearCultivos:
			return {
				...state,
				cultivos: [],
			};

		case types.removeCultivo:
			const cultivosFiltered = state.cultivos.filter((cultivo) => cultivo.id !== action.payload);
			return {
				...state,
				idCultivoSelected: null,
				nombreCultivo: null,
				claveCultivo: null,
				subciclo: null,
				cuotaCultivo: null,
				inicioBc: "",
				finBc: "",
				inicioSonora: "",
				finSonora: "",
				superficiePreviaCultivo: null,
				requiereDictamen: null,
				requiereComplementoVolumen: null,
				requiereControlCPUS: null,
				cultivos: [...cultivosFiltered],
			};

		case types.setCultivo:
			return {
				...state,
				idCultivoSelected: action.payload.id,
				nombreCultivo: action.payload.nombre,
				claveCultivo: action.payload.clave,
				subciclo: action.payload.subciclo,
				cuotaCultivo: action.payload.costoHectarea,
				costoSanidades: action.payload.costoSanidades,
				inicioBc: action.payload.inicioBc,
				finBc: action.payload.finBc,
				inicioSonora: action.payload.inicioSonora,
				finSonora: action.payload.finSonora,
				superficiePreviaCultivo: action.payload.superficiePrevia,
				requiereDictamen: action.payload.requiereDictamen ?? false,
				requiereComplementoVolumen: action.payload.requiereComplementoVolumen ?? false,
				requiereControlCPUS: action.payload.requiereControlCPUS ?? false,
			};

		case types.setCuotaCultivo:
			return {
				...state,
				cuotaCultivo: action.payload,
			};

		case types.unsetCultivo:
			return {
				...state,
				idCultivoSelected: null,
				nombreCultivo: null,
				claveCultivo: null,
				subciclo: null,
				cuotaCultivo: null,
				requiereDictamen: false,
				requiereComplementoVolumen: false,
				requiereControlCPUS: false,
				opcionDeExpedicion: "",
			};

		case types.setCultivoAnterior:
			return {
				...state,
				idCultivoAnteriorSelected: action.payload.id,
				cultivoAnterior: action.payload.nombre,
				claveCultivoAnterior: action.payload.clave,
			};

		case types.unsetCultivoAnterior:
			return {
				...state,
				idCultivoAnteriorSelected: null,
				cultivoAnterior: "",
				claveCultivoAnterior: null,
			};

		case types.setTipoSemilla:
			return {
				...state,
				tipoSemilla: action.payload,
			};

		case types.setComplemento:
			return {
				...state,
				supComplementoRequerida: action.payload.supComplementoRequerida,
				restoSupComplementoRequerida: action.payload.restoSupComplementoRequerida,
			};

		//Usuarios **************************************

		case types.altaPermisoOpenUsuariosModal:
			return {
				...state,
				openUsuariosModal: true,
			};

		case types.altaPermisoCloseUsuariosModal:
			return {
				...state,
				openUsuariosModal: false,
			};

		case types.setUsuarios:
			return {
				...state,
				usuarios: action.payload,
			};

		case types.clearUsuarios:
			return {
				...state,
				usuarios: [],
			};

		case types.setUsuario:
			return {
				...state,
				idUsuarioSelected: action.payload.id,
				cuenta: `${action.payload.cuenta}.${action.payload.subcta}`,
				usuario: `${action.payload.apPaterno} ${action.payload.apMaterno} ${action.payload.nombre}`,
				supDerecho: action.payload.supRiego,
				lote: action.payload.predio,
				localidad: action.payload.localidad,
				municipio: action.payload.municipio,
				estado: action.payload.estado,
				modulo: action.payload.modulo,
				zona: action.payload.zona,
				seccion: action.payload.seccion,
				canal: `${action.payload.cp}-${action.payload.lt}-${action.payload.slt}-${action.payload.ra}-${action.payload.sra}-${action.payload.ssra}`,
				toma: action.payload.pControl,
				sistema: action.payload.sistRiego,
				supPrevia: action.payload.supPrevia,
				reacomodo: action.payload.reacomodo,
				nombreLocalidad: action.payload.nombreLocalidad,
				tipoLocalidad: action.payload.tipoLocalidad,
				claveLocalidad: action.payload.ejido,
				dictamen: action.payload.dictamen,
				laboresPendientes: action.payload.laboresPendientes,
				superficieParcialLiberada: action.payload.superficieParcialLiberada,
			};

		case types.unsetUsuario:
			return {
				...state,
				idUsuarioSelected: null,
				cuenta: null,
				usuario: null,
				supDerecho: null,
				lote: null,
				localidad: null,
				municipio: null,
				estado: null,
				modulo: null,
				seccion: null,
				canal: null,
				toma: null,
				sistema: null,
				cultivosAnteriores: [
					{ clave: 0, id: "zaNmqPpV7fVmcSDZ4bu7", nombre: "SIN CULTIVO", superficie: 0 },
				],
			};

		//Productores **************************************

		case types.altaPermisoOpenProductoresModal:
			return {
				...state,
				openProductoresModal: true,
			};

		case types.altaPermisoCloseProductoresModal:
			return {
				...state,
				openProductoresModal: false,
			};

		case types.loadProductores:
			return {
				...state,
				productores: action.payload,
			};

		case types.clearProductores:
			return {
				...state,
				productores: [],
			};

		case types.setProductor:
			return {
				...state,
				idProductorSelected: action.payload.id,
				nombreProductor: `${action.payload.apPaterno} ${action.payload.apMaterno} ${action.payload.nombre}`,
				rfcProductor: action.payload.rfc,
				curpProductor: action.payload.curp,
				productorIncumplido: action.payload.incumplido ? action.payload.incumplido : false,
				concesionesProductor: action.payload.concesiones ? action.payload.concesiones : false,
			};

		case types.setComplementos:
			return {
				...state,
				complementosProductor: action.payload,
			};

		case types.setPermisosComplemento:
			return {
				...state,
				permisosComplemento: action.payload,
			};

		case types.unsetPermisosComplemento:
			return {
				...state,
				permisosComplemento: [],
				observaciones: "",
			};

		case types.unsetProductor:
			return {
				...state,
				idProductorSelected: null,
				nombreProductor: null,
				rfcProductor: null,
				productorIncumplido: null,
			};

		case types.setProductoresAnteriores:
			return {
				...state,
				productoresAnteriores: action.payload,
			};

		//Nuevo Productor **************************************

		case types.altaPermisoOpenNuevoProductorModal:
			return {
				...state,
				isOpenNuevoProductorModal: true,
			};

		case types.altaPermisoCloseNuevoProductorModal:
			return {
				...state,
				isOpenNuevoProductorModal: false,
			};

		//Set Form *****************************************************

		case types.altaPermisosSetFormValues:
			return {
				...state,
				supAutorizada: Number(action.payload.supAutorizada),
				variedad: action.payload.variedad,
				fuenteCredito: action.payload.fuenteCredito,
				latitud: action.payload.latitud,
				longitud: action.payload.longitud,
				observaciones: action.payload.observaciones,
			};

		// Al guardar

		case types.altaPermisosSetSubmitData:
			return {
				...state,
				tipo: action.payload.tipo,
				ciclo: action.payload.ciclo,
				numeroPermiso: action.payload.numeroPermiso,
				fechaEmicion: action.payload.fechaEmicion,
				fechaLimite: action.payload.fechaLimite,
				vigencia: action.payload.vigencia,
				estadoPermiso: action.payload.estadoPermiso,
				mensajeFijo: action.payload.mensajeFijo,
			};

		case types.altaPermisosUnsetSubmitData:
			return {
				...state,
				// tipo: null,
				ciclo: null,
				numeroPermiso: null,
				fechaEmicion: null,
				fechaLimite: null,
				vigencia: null,
			};

		case types.altaPermisosSetEnEspera:
			return {
				...state,
				enEspera: true,
			};

		case types.altaPermisosUnsetEnEspera:
			return {
				...state,
				enEspera: false,
			};

		case types.altaPermisosEnableSaveButton:
			return {
				...state,
				enableSaveButton: true,
			};

		case types.altaPermisosDisableSaveButton:
			return {
				...state,
				enableSaveButton: false,
			};

		case types.altaPermisosEnablePrintButton:
			return {
				...state,
				enablePrintButton: true,
			};

		case types.altaPermisosDisablePrintButton:
			return {
				...state,
				enablePrintButton: false,
			};

		case types.setTipoNormal:
			return {
				...state,
				tipo: "normal",
			};

		case types.setTipoExtra:
			return {
				...state,
				tipo: "extra",
			};

		default:
			return state;
	}
};
